import {
  ref, watch,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { isEnableLocationModule } from '@/auth/utils';
import smartDeviceStoreModule from '@/views/smart-device/smartDeviceStoreModule';

export default function useReportDebt() {
  // Use toast
  const toastification = toast();

  if (!store.hasModule('smartDevice')) {
    store.registerModule('smartDevice', smartDeviceStoreModule);
  }

  const blankItem = {
    id: 0,
    apartment: null,
    floor: null,
    name: '',
    price: null,
    deposit: null,
    size: null,
    active: true,
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  const rows = ref([]);
  // Table Handlers
  const columns = [

    {
      label: 'Khách hàng',
      field: 'contract.name',
    },
    {
      label: 'Tòa nhà',
      field: 'contract.apartment.name',
    },
    {
      label: 'Phòng',
      field: 'contract.room.name',
    },
    {
      label: 'Giường',
      field: 'contract.bed.name',
      hidden: rows.value.filter(row => row.bed && row.bed.id > 0).length === 0,
    },
    {
      label: 'Tổng tiền tháng này',
      field: 'total',
      type: 'number',
      width: '140px',
    },
    {
      label: 'Đã thanh toán',
      field: 'paid',
      type: 'number',
      width: '140px',
    },
    {
      label: 'Còn nợ',
      field: 'remain',
      type: 'number',
      width: '140px',
    },
    {
      label: 'Thao tác',
      field: 'action',
      tdClass: 'text-center',
      sortable: false,
      width: '100px',
      hidden: process.env.VUE_APP_PROVIDER === 'tingtong',
    },

  ];

  // filter
  const apartment = ref(null);
  const location = ref(null);
  const room = ref(null);
  const searchTerm = ref('');
  // current selected rows
  const selectedRows = ref([]);
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call
  const fetchRooms = () => {
    isLoading.value = true;
    store
      .dispatch('report/getReportDebt', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
        isLoading.value = false;
      })
      .catch(error => {
        toastification.showToastError(error);
        isLoading.value = false;
      });
  };

  const fetchData = () => {
    fetchRooms();
  };

  const exportData = () => {
    store
      .dispatch('report/exportReportDebt', serverParams.value)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const stopProvidingElectric = dataItem => {
    store
      .dispatch('smartDevice/changeDeviceStatus', { roomId: dataItem.contract.room.id, enable: false })
      .then(() => {
        toastification.showToastSuccess('Ngừng cấp điện thành công');
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const onViewDetailItem = val => {
    item.value = val;
  };
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  //   Watch
  watch(location, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.locationId = val.id;
    } else {
      delete filter.locationId;
    }
    updateParams({ filter });
  });
  watch(apartment, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.apartmentId = val.id;
    } else {
      delete filter.apartmentId;
    }
    updateParams({ filter });
  });
  watch(room, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.roomId = val.id;
    } else {
      delete filter.floorId;
    }
    updateParams({ filter });
  });
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });

  return {
    item,
    columns,
    rows,
    apartment,
    room,
    searchTerm,
    selectedRows,
    isLoading,
    totalRecords,
    serverParams,
    location,
    isEnableLocationModule,
    fetchData,
    fetchRooms,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    onViewDetailItem,
    exportData,
    stopProvidingElectric,
  };
}
